import React from "react";
import IconText from "./iconText";


const IconWindow = ({icons}) => {
    return (<div className="grid-x cell-window">
        {icons.map((icon, index) => (
            <div className={`large-6 cell ${index > 1 ? "vertical-spacer" : ""}`} key={icon.id}>
                <IconText icon={icon}/>
            </div>
        ))}
    </div>)
}

export default IconWindow
