import React from "react";
import {graphql} from "gatsby";
import CommandIcon from "../assets/images/icons/command.svg"
import TribuneIcon from "../assets/images/icons/tribune.svg"
import HeartIcon from "../assets/images/icons/like.svg"
import IconWindow from "../components/iconWindow/iconWindow";
import PartnersGrid from "../components/partnersGrid/partnersGrid";
import {Trans, useI18next} from "gatsby-plugin-react-i18next";
import Seo from "../components/seo/seo";

const pageData = (data, t) => {
    return {
        icons: [{
            id: "team",
            image: CommandIcon,
            text: t("hero_text_team")
        }, {
            id: "tribune",
            image: TribuneIcon,
            text: t("hero_text_tribune")
        }, {
            id: "heart",
            image: HeartIcon,
            text: t("hero_text_heart")
        }],
        partners: [{
            id: "orgs",
            title: {
                en: "COMPETITION ORGANISERS",
                ru: "ОРГАНИЗАТОРЫ СОРЕВНОВАНИЙ"
            },
            large: true,
            partners: data.allPartnersJson.nodes.map((node) => ({
                id: node.id,
                href: node.href,
                image: {
                    publicURL: node.image.publicURL
                }
            }))
        }]
    }
}
const Index = ({data, location}) => {
    const {t, language} = useI18next()
    const page = pageData(data, t)
    return (
        <>
            <Seo title={t('main_page_title')} lang={language} description={t('welcome_text')}
                 pathname={location.pathname} metaYandexVerification="35f135e4ba67092a" />
            <section className="welcome bg-first">
                <div className="grid-x">
                    <div className="large-offset-1 large-3 cell">
                        <Trans>welcome_text</Trans>
                        <hr/>
                    </div>
                </div>
            </section>
            <section>
                <div className="grid-x">
                    <div className="large-offset-1 large-4 cell cell-text">
                        <h1><Trans>what_is_header_1</Trans></h1>
                        <h1><Trans>what_is_header_2</Trans></h1>
                        <Trans>what_is_text_1</Trans>
                        <br/>
                        <br/>
                        <Trans>what_is_text_2</Trans>
                        <hr/>
                    </div>
                </div>
            </section>
            <section>
                <div className="grid-x bg-second">
                    <div className="large-offset-1 large-2 cell cell-text">
                        <h1>
                            <Trans>hero_header_1</Trans>
                        </h1>
                        <h1>
                            <Trans>hero_header_2</Trans>
                        </h1>
                    </div>
                    <div className="large-6 large-offset-2 cell cell-text">
                        <IconWindow icons={page.icons}/>
                    </div>
                </div>
            </section>
            <section>
                <div className="grid-x">
                    <div className="large-offset-1 large-10 cell">
                        <PartnersGrid partnersSections={page.partners} language={language}/>
                    </div>
                </div>
            </section>
        </>
    )

}

export const query = graphql`
    query($language: String!){
        allPartnersJson(filter: {id: {in: ["dit", "su", "sroo", "is", "ct"]}}){
            nodes{
                id
                href
                image {
                    publicURL
                }
            }
        }
        locales: allLocale(filter: {language: {eq: $language}}){
            edges{
                node{
                    ns
                    data
                    language
                }
            }
        }
    }
`

export default Index
