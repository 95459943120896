import React from "react";

const IconText = ({icon}) => {
    let iconElement = <img src={icon.image} alt={icon.id}/>
    if (icon.linkImage) {
        iconElement = <a href={icon.href} target="_blank" rel="noreferrer noopener">{iconElement}</a>
    }
    return (
        <div className="grid-x">
            <div className="cell large-12 text-center">
                {iconElement}
            </div>
            <div className="cell large-12 align-center-middle text-center">
                {icon.text}
            </div>
            {icon.href && (
                <div className="cell large-12 align-center-middle text-center">
                    <a href={icon.href} target="_blank"
                       rel="noreferrer noopener">{icon.hrefText ? icon.hrefText : icon.href}</a>
                </div>
            )}

        </div>)
}

export default IconText
